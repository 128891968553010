<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account
      </v-icon> User Profile
    </h2>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-card
        class="my-vcard-style"
      >
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.company"
                label="Company"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.referenceId"
                label="Reference Id"
                class="purple-input"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.firstName"
                label="First Name*"
                class="purple-input"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.lastName"
                label="Last Name*"
                class="purple-input"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <address-form
                ref="addressForm"
                @addressEntered="assiginAddress($event)"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.phoneNumber"
                label="Mobile"
                class="purple-input"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.email"
                label="Email Address*"
                class="purple-input"
                disabled
                hint="Please contact support for email change."
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.email"
                class="purple-input"
                label="User Name"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.role"
                label="Role"
                class="purple-input"
                disabled
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="permissionCheck('user-edit')"
            color="#FF3700"
            @click="updateUser()"
          >
            Update Profile
          </v-btn>
        </v-card-actions>
      </v-card>
      <br>
      <v-snackbar
        v-model="sucAlert"
        color="success"
        icon="mdi-check-circle"
        transition="scale-transition"
        top
        right
      >
        {{ alertMessage }}
      </v-snackbar>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import Constants from 'src/constants';
 import AddressForm from 'src/views/dashboard/component/AddressForm';
 import global from 'src/mixins/global';

 export default {
    name: 'UserProfile',
    components: {
      'centre-spinner': spinner,
      'address-form': AddressForm,
    },
    mixins: [global],
    data: () => ({
      formData: {
        firstName: 'user',
        lastName: '',
        email: '',
        company: '',
        phoneNumber: null,
        address: {},
        role: 'normal',
        referenceId: '',
      },
      alertMessage: 'User profile updated',
      sucAlert: false,
      loading: false,
    }),
    computed: {
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
    },
    async mounted () {
      await this.getUser();
      this.fillData();
    },
    methods: {
      fillData () {
        this.formData.firstName = this.userProfile.first_name;
        this.formData.lastName = this.userProfile.last_name;
        this.formData.email = this.userProfile.email;
        this.formData.company = this.userProfile.organization.external_id;
        this.formData.phoneNumber = this.userProfile.phone_number;
        this.formData.role = this.userProfile.role.name;
        this.formData.referenceId = this.userProfile.external_id;
        this.$refs.addressForm.fillData(this.userProfile.address);
      },
      async getUser () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchUserProfile');
        this.loading = false;
      },
      updateUser () {
        this.$refs.addressForm.getEnteredAdress();
        if (Object.keys(this.formData.address).length > 0) {
        this.loading = true;
        this.$store.dispatch('userprofile/updateUserProfile', {
         details: {
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          email: this.formData.email,
          organization_id: this.formData.company,
          phone_number: this.formData.phoneNumber,
          role: this.formData.role,
          address: this.formData.address,
        },
          referenceId: this.formData.referenceId,
        }).then(response => {
            this.$store.dispatch('alert/onAlert', {
            message: 'Profile updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
            this.getUser();
            this.loading = false;
            this.formData.address = {};
          })
          .catch(errors => {
            this.loading = false;
            this.errors.record(errors.response.data);
          });
        }
      },
       assiginAddress (address) {
        this.formData.address = address;
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
